// --------------------------------------------------
// Layout default
// --------------------------------------------------
body {
  font-family: 'Merriweather', serif;
  background-color:  rgba(255, 255, 200, 0.2);
}

em {
  font-family: 'Merriweather Italic', serif;
  font-style: normal;
  // color: $link_color;
  font-size: 101%;
}

a {
    color: $link_color;
    border-bottom: 2px solid transparent;
}
a:hover {
    color: $link_color;
    border-bottom: 2px dotted $link_color;
}
a.button {
    text-align: left;
    border: 2px dotted red;
    background-color: transparent;
    color: black;

    .small {
      color: black;
    }
  }
.button { font-size: 19px; }

hr {
    border-color: currentcolor currentcolor $link_color;
    border-style: none none dotted;
    border-width: 0 0 2px;
}
p, li, blockquote {
    font-size: 18px;
    line-height: 1.85rem;
}

blockquote, blockquote p {
    color: $link_color;
    line-height: 1.6;
    border-left: 2px dotted $link_color;

    footer cite {
      color: $link_color;
      font-family: dosisbold,sans-serif;
      font-size: 18px;
      line-height: 22px;
      font-style: normal;
      font-weight: normal;
      @include breakpoint(xxlarge) {
        font-size: 16px;
         margin-top: -260px;
      }
    }

}
// between <p>
footer cite {
  color: $link_color;
  font-family: dosisbold,sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  @include breakpoint(xxlarge) {
        font-size: 16px;
        margin-left: -180px;
        margin-top: -220px;
        width: 140px;
        position: absolute;
        text-align: right;
  }
}
cite::before {
    content: "";
}
figure {
    margin: 10px -40px 40px;
    @include breakpoint(small only) {
      margin: 40px -10px 0;
    }
    figcaption {
      color: #555;
      font-family: dosisbold,sans-serif;
      font-size: 18px;
      font-weight: normal;
      @include breakpoint(xxlarge) {
        font-size: 16px;
        margin-left: -170px;
        margin-top: -220px;
        max-width: 150px;
        position: absolute;
        text-align: right;
      }
      @include breakpoint(small only) {
        margin: 10px 12px 20px;
      }
    }
}
// index page only
main#index figure {
  margin: 40px -40px;
  @include breakpoint(small only) {
    margin: 40px -10px 0;
  }
}


ul.toc {
  list-style-type: none;
  margin: 0;


}


iframe {
    margin: 40px 0 50px;
}
// within a figure
figure iframe {
    margin: 20px 0 0 0;
}



::-moz-selection { background: $selection_color; color: white; }
::selection { background: $selection_color; color: white; }


p::-moz-selection, li::-moz-selection, em::-moz-selection { background: $selection_color; color: black; }
p::selection, li::selection, em::selection { background: $selection_color; color: black; }

#theme_switch {
    background-color: transparent;
    border: 0px solid black;
    left: 0;
    min-height: 100px;
    min-width: 100px;
    position: fixed;
    top: 0;
    width: 100px;
    z-index: 99999999;
}
#page  {
  border: 2px dotted $link_color;
  margin: 50px auto;
  transform: rotate(-2deg);
  background-color: rgba(255, 255, 255, 0.5);
  max-width: 75em;
  @include breakpoint(xlarge only) {
    max-width: 65em;
    margin: 40px auto;
    transform: rotate(-1deg);
  }
  @include breakpoint(large only) {
    max-width: 55em;
    margin: 30px auto;
    transform: rotate(-1deg);
  }
  @include breakpoint(medium only) {
    max-width: 55em;
    margin: 20px auto;
    transform: rotate(-1deg);
  }
  @include breakpoint(small only) {
    max-width: 100%;
    margin: 0 0;
    border: 0px dotted $title_color;
    transform: rotate(0deg);
  }
}
#page_inner {
  transform: rotate(2deg);
  border: 2px dotted $title_color;
  background-color: rgba(255, 255, 255, 1);
  @include breakpoint(xlarge only) {
    transform: rotate(1deg);
  }
  @include breakpoint(large only) {
    transform: rotate(1deg);
  }
  @include breakpoint(medium only) {
    transform: rotate(1deg);
  }
  @include breakpoint(small only) {
    transform: rotate(0deg);
    border: 0px dotted red;
  }
}


main {
  padding-top: 20px;
  margin-top: 142px;


  @include breakpoint(xxlarge only) {
    margin-top: 175px;
  }
  @include breakpoint(xlarge only) {
    margin-top: 165px;
  }
  @include breakpoint(large only) {
    margin-top: 125px;
  }
  div#breadcrump {
    // font-family: dosisbold,sans-serif;
    font-size: 18px;
    font-weight: normal;
  }

  div#description {
    color: $link_color;
    // font-family: dosisbold,sans-serif;
    font-size: 18px;
    font-weight: normal;
    @include breakpoint(xxlarge) {
      font-family: dosisbold,sans-serif;
      font-size: 16px;
      margin-left: -170px;
      margin-top: 114px;
      width: 150px;
      position: absolute;
      text-align: right;
    }
  }
  div.row {
    padding: 0 10px;
    @include breakpoint(large) {
      padding: 0 70px;
      max-width: 55rem;
    }

    @include breakpoint(small) {
      max-width: 40rem;
    }
    div.row {
       padding: 0;
    }
  }
  div.row.row-wide-text,
  div.row.row-wide-foto,
  div.row.row-wide-video
  {
    padding: 0 10px;
    @include breakpoint(large) {
      padding: 0 70px;
    }
    @include breakpoint(xlarge) {
      padding: 0 70px;
    }
    div.large-6.columns {
      padding-left: 0;
      padding-right: 0;
    }
    @include breakpoint(medium up) {
      div.large-6.columns:first-child {
        padding-left: 0;
        padding-right: 0.9375rem;
      }
      div.large-6.columns:last-child {
        padding-left: 0.9375rem;
        padding-right: 0;
      }
    }
    div.large-12.columns {
      padding-left: 0;
      padding-right: 0;
    }
  }
  main#content {
      padding: 0 70px;

      @include breakpoint(small only) {
          padding: 10px 10px;

      }
  }
}
