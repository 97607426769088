// --------------------------------------------------
// Layout index page
// --------------------------------------------------

main {
  @include grid-row;

  //other elements
  //@include grid-column;
}

main#content div#sitemap ul  {
  list-style-type: none;
   margin-left: 1.9rem;
  li {
    text-indent: -30px;
  }
}
