// --------------------------------------------------
// Headings
// --------------------------------------------------



h1,h2,h3,h4,h5,h6  {
  font-family: dosisregular, sans-serif;
  font-weight: normal;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h1 {
    color: $title_color ;
    font-size: 4rem;
    line-height: 4rem;
    text-transform:uppercase;
    font-family: dosisbold,sans-serif;
    text-shadow: 2px 2px 0 #444;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    sup {
      top: -0.8em;
      font-size: 60%;
    }
    @include breakpoint(small only) {
     font-size: 2.5rem;
     line-height: 2.5rem;
      sup {
        top: -0.2em;
        font-size: 60%;
      }
    }
}
main#Schoolbook h1 {
    font-size: 4.5rem;
    line-height: 4.5rem;

}

h2 {
    color: $title_color ;
    font-size: 2.7rem;
    line-height: 3rem;
    margin-bottom: 2.5rem;
    text-transform:uppercase;
    font-family: dosisbold,sans-serif;

}
h3 {
    color: black;
    font-family: dosisbold,sans-serif;
    font-size: 2rem;
    line-height: 2.3rem;
   margin: 1em 0;
}
main#index h3 {
    color: $title_color;

}
h4 {
    color: $link_color;
    font-family: dosismedium,sans-serif;
    font-size: 2rem;
    line-height: 2.3rem;
    margin: 2rem 0;
    display: inline-block;
}
