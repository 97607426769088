// --------------------------------------------------
// Functions
// --------------------------------------------------


main#index {

  ul.orbit-container {
    li.orbit-slide {
      border: 2px dotted red;
      margin: 0 -1px;
    }

    figure.orbit-figure  {
      margin: 0 !important;
      figcaption {
        font-size: 16px;
        margin-left: 0;
        margin-top: 0;
        max-width: none;
        position: absolute;
        text-align: right;
        background-color: rgba(0, 0, 0, 0.4);
        color: #222;
        opacity: 0.6;

      }
    }
  }
}


.orbit-previous, .orbit-next {
    color: white;
    background: #144D4D;
    opacity: 0.6;
}
