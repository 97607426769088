
footer#footer {
  @include grid-row;
  background-color: white;
  border-top: 2px dotted $title_color;
  border-right: 2px dotted $title_color;
  margin-left: 0;
  margin-top: 80px;
  padding: 10px 20px;
  font-family: dosismedium, sans-serif;
  font-size: 115%;

  max-width: 63rem;

  @include breakpoint(small only) {
    border-top: 2px dotted red;
    border-right: 0 dotted red;
  }
  nav {
    @include grid-column;
    float: right;
    width: auto;
  }

  .menu > li > a {
    display: block;
    line-height: 1.5;
    // padding: 0.3rem 1rem 0.7rem;
    padding: 0;
    margin: 0.3rem 1rem;
  }



}
#to-top {
    bottom: 0;
    @include breakpoint(xxlarge only) {
      bottom: -50px;
    }
    float: right;
    margin: 0 15px;
    position: relative;
    right: 0;
    width: 70px;
  a:hover {
    border: none;
  }
  svg {
    transform: scale(0.8);
    opacity: 0.6;
    transition: 1s all;
    #arrow-top {
      fill: #ffffff;
      transition: 1.5s all;
    }
  }
  svg:hover {
    transform: scale(0.8);
    opacity: 0.6;
    transition: 1s all;
    #arrow-top {
      fill: #144D4D;
      transition: 1.5s all;
    }
  }
}