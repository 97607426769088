// --------------------------------------------------
// Config
// --------------------------------------------------

//
// Colors
// --------------------------------------------------

$black: #000;
$white: #fff;


$link_color: rgb(0, 70, 170);
$link_color_brighter: rgb(0, 84, 204);

$selection_color: rgba(0, 70, 170, 0.5);

// $title_color: rgb(227, 6, 20);
$title_color: rgb(244, 6, 20);

// preload redish
main .preload a.button,
main div#description.preload,
.preload {
  color: $title_color;
  background-color: $title_color;
  text-shadow: 0 0 0 transparent;
  a {
    color: transparent;
  }
}
// perload blueish
header h1 a .marker.preload {
  color: $link_color;
  background-color: $link_color;
  text-shadow: 0 0 0 transparent;
}
.loaded {
  transition: 1s background-color;
}

.hide { display: none; }
