body {
  background-color: rgba(255, 255, 255, 0.5);
  transition: 1.5s background-color;
}
body.darken {
  background-color: rgba(65, 65, 65, 1);
  transition: 1.5s background-color;
}

#page {
  background-color: rgba(255, 255, 255, 0.5);
  transition: 1.5s background-color;
  #page_inner {
    border: 2px dotted $title_color;
  }
}
#page.darken {
  transition: 1.5s background-color;
  border: 2px dotted #000;
  background-color: rgba(55, 55, 55, 0.5);
  #page_inner {
    border: 2px dotted #000;
  }
}


#header_img {

    border-bottom: 2px dotted #ffffff;
    @supports (-webkit-appearance:none) and (not (overflow:-webkit-marquee))
      and (not (-ms-ime-align:auto)) and (not (-moz-appearance:none)) {
         border-bottom: 2px dotted #f50000;
      }
    height: 350px;

    background-color: rgb(245,0,0);
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    transition: 1.5s all;

    @include breakpoint(small only) {
      height: 290px;

    }
    img {
      width: 100%;
      margin-top: 142px;
      transition: 1.5s all;
      @include breakpoint(medium only) {
        margin-top: -75px;
      }
      @include breakpoint(large only) {
        margin-top: -200px;
      }
      @include breakpoint(xlarge only) {
        margin-top: -200px;
      }
      @include breakpoint(xxlarge only) {
        margin-top: -250px;
      }
    }
}
/*
#header_img:before {
    display: block;
    position: absolute;
    border: 0 solid red;
    width: 100px;
    height: 100px;
    transform: scale(0.7) rotate(20deg);
    transition: 1.5s all;
    opacity: 0.4;
    content: url(/assets/img/mz_magnifier_01.svg);
    // left: -20px;
}
*/



#header_img:hover {
  cursor: pointer;

}

/*#header_img:hover:before {
  opacity: 0.7;
  transform: scale(0.7) rotate(20deg);
  transition: 1.5s all;

}
#header_img.fullsize:before {
  opacity: 0.2;
  transform: scale(0.7) rotate(20deg);
  transition: 1.5s all;
}
*/
#header_img:before {
    display: block;
    position: absolute;
    border: 0 solid red;
    width: 100px;
    height: 100px;
    transform: scale(0.5);
    transition: 2s all;
    opacity: 0;
    content: url(/assets/img/mz_cross_01.svg);
}
#header_img.fullsize:before {
    opacity: 0.4;
    transition: 2s all;
    content: url(/assets/img/mz_cross_01.svg);
}

#header_img.fullsize:hover:before {
  opacity: 0.5;
  transition: 1.5s all;
  content: url(/assets/img/mz_cross_01_act.svg);
}
#header_img.fullsize {
    border-bottom: 0px dashed rgb(240,0,0);
    height: auto;
    overflow: hidden;
    position: fixed;
    height: 100%;
    transition: 1.5s all;
    @include breakpoint(small only) {
      // margin-top: 227px;
    }
    img {
      width: 100%;
      margin-top: 0;
      transition: 1.5s all;
      @include breakpoint(small only) {
        margin-top: 145px;
      }
      @include breakpoint(medium only) {
        margin-top: 0px;
      }
      @include breakpoint(large only) {
        margin-top: -20px;
      }
      @include breakpoint(xlarge only) {
        margin-top: 0px;
      }
      @include breakpoint(xxlarge only) {
        margin-top: 0px;
      }
    }
}
#header_img.compact {
    border-bottom: 0px dashed rgb(240,0,0);
    height: 91px;
    overflow: hidden;
    position: fixed;
    width: auto;
    transition: 0.5s all;

  img {
    width: 100%;
    margin-top: -532px;
  }
}

@include breakpoint(medium only) {
  main .row {
      max-width: 40rem;
  }
}

header {
  @include grid-row;
  background-color: white;
  border-bottom: 2px dotted $title_color;
  border-left: 2px dotted $title_color;
  left: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: 0;
  max-width: 50%;
  padding: 10px 20px 29px 45px;
  position: relative;
  right: auto;
  top: auto;
  transition: all 0.5s ease 0s;
  z-index: 8888;

  @include breakpoint(small only) {
    border-bottom: 2px dotted red;
    border-left: 0 dotted red;
    max-width: 100%;
    padding: 10px;
    padding: 10px 10px 20px 10px !important;
  }


  nav {
    @include grid-column;
    float: right;
    width: auto;
  }

  .menu > li > a {
    display: block;
    font-size: 80%;
    line-height: 1;
    padding: 0.3rem;
    margin-left: 1rem;
  }


  h1 {
    color: $title_color;
    font-weight: normal;
    font-family: dosisbold,sans-serif;
    font-size: 2.5rem;
    line-height: 1;
    margin: 20px 0 0;
    text-transform: uppercase;
    text-shadow: 2px 2px 0 #444;


    @include breakpoint(small only) {
     font-size: 1.7rem;
     margin: 30px 0 0 0;
    }

    a {
        color: $title_color;
        border: none;
        span.marker {
                color: $link_color_brighter;
                text-transform: none;
        }
    }
    a:hover {
        color: rgb(220,0,0);
        border: none;
        border-bottom: 0px solid $title_color;

    }
  }
}
header.transparent {
  opacity: 0.2;
  transition: 1.5s all;
}

header.compact {
    margin-bottom: 50px;
    margin-right: 0;
    min-width: 800px;
    padding: 10px 40px 17px 20px;
    position: fixed;
    right: 0;
    transition: 0.5s all;

  h1 {
    font-size: 1.8rem;
  }
}

main {
  opacity: 1;
  transition: 1.5s opacity;
}

main.transparent {
  opacity: 0.05;
  transition: 1.5s opacity;
}

#collector {
  margin: 400px 0 0 0;
  border-top: 2px dotted #664444;
  border-bottom: 2px dotted #664444;
  background-color: rgba(255,255,255,0.8);
}